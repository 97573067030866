<template>
  <div>
    <template v-if="result.valueArray.length > 1">
      <trash v-on="$listeners" :result="result"></trash>
    </template>
  </div>
</template>
<script>
import trash from "@/commonComponents/dynamic/trash.vue";
export default {
  components: { trash },
  props: ["result", "fieldAttributes"],
};
</script>